<template>
  <div class="comm-notice-container center column">

    <div class="comm-notice">
      <div clsas="comm-notice-title">
        <p class="comm-notice-title-text">공지사항</p>
      </div>


    <hr>
    <table class="comm-notice-table">
      <tr  class="comm-notice-table-header">
        <th class="comm-notice-table-number">NO</th>
        <th class="comm-notice-table-title">제목</th>
        <th class="comm-notice-table-writer">작성자</th>
        <th class="comm-notice-table-date">날짜</th>
        <th class="comm-notice-table-view">조회수</th>
      </tr>

      <tr class="comm-notice-table-content cursor"
      v-for="(item, index) in NOTICE_LIST"
      :key="'comm-notice-table'+index"
      @click="onPost(item._id)">
        <td class="comm-notice-table-number">{{(NOTICE_LIST_TOTAL) - index - ((pageIndex-1)*10)}}</td>
        <td class="comm-notice-table-title">{{item.title}}</td>
        <td class="comm-notice-table-writer">{{item.writer}}</td>
        <td class="comm-notice-table-date">{{changeDateFormat(item.created_at)}}</td>
        <td class="comm-notice-table-view">{{item.view}}</td>
      </tr>
    </table>

    <div class="comm-notice-pagination-container">
      <button class="comm-notice-pagination-btn cursor"
      @click="onPrevious()">
        <img class="comm-notice-pagination-image" :src="paginationBtnLeftIcon"/>
      </button>
      <div class="comm-notice-pagination">
        <span class="comm-notice-pagination-current">{{pageIndex}}</span>
        /
        <span class="comm-notice-pagination-total">{{totalIndex}}</span>
      </div>
      <button class="comm-notice-pagination-btn cursor"
      @click="onNext()">
        <img class="comm-notice-pagination-image" :src="paginationBtnRightIcon"/>
      </button>
    </div>

    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import paginationBtnLeftIcon from '../../assets/icons/pagination_btn_left_icon.svg';
import paginationBtnRightIcon from '../../assets/icons/pagination_btn_right_icon.svg';

import moment from 'moment';

export default {
  data() {
    return {
      pageIndex : 1,
      paginationBtnLeftIcon,
      paginationBtnRightIcon,
    }
  },

  created() {
    this.FETCH_NOTICE_LIST(this.pageIndex)
  },

  methods : {
    ...mapActions([
      'FETCH_NOTICE_LIST'
    ]),

    onPrevious() {
      if(this.pageIndex == 1) return
      this.pageIndex -= 1;
      this.FETCH_NOTICE_LIST(this.pageIndex)
    },

    onNext() {
      if(this.pageIndex == this.totalIndex) return
      this.pageIndex += 1;
      console.log(this.pageIndex)
      this.FETCH_NOTICE_LIST(this.pageIndex)
    },

    changeDateFormat(date) {
        const d = new Date(date)
        return moment(d).format('YY-MM-DD')
    },

    onPost(id) {
      this.$router.push('/comm/notice/'+id)
    }
  },

  computed : {
    ...mapState([
      'NOTICE_LIST', 'NOTICE_LIST_TOTAL'
    ]),

    totalIndex() {
      const count = this.NOTICE_LIST_TOTAL;
      return parseInt(count/10) + (parseInt(count%10)>0? 1:0);
    }
  }
}
</script>

<style>

</style>
